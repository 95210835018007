.shopping-cart-content {
    position: fixed;
    top: 0;
    right: 0;
    background-color: $white;
    height: 100%;
    color: $black;
    z-index: 1001;
    text-align: left;
    right: -100%;

    width: calc(100% - 50px);
    max-width: 360px;
    z-index: 1150;


    .shopping-cart-header {
        padding: 10px;
        background-color: $gray-dark;
        color: $white;
        font-weight: 500;
        font-size: $font-size-h4;
        cursor: pointer;

        i {
            color: $white !important;
            font-size: 24px;
        }

        .products-count {
            float: right;
        }
    }
    
    .shopping-cart-container {
        height: calc(100% - 60px);
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .shopping-cart-summary {
        margin-top: 15px;
        padding: 10px;

        .item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            border-top: 1px solid $border-gray;
            padding-top: 10px;

            .item-value {
                color: $link-color-hover;
                font-size: 1rem;
            }
        }

        .btn {
            width: 100%;
        }
    }

    .product-container {
        padding: 10px;
        overflow: auto;
        white-space: normal;
        font-size: 14px;


        .no-product-label {
            text-align: center;
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 80px;
            line-height: normal;
        }

        .no-product-icon {
            padding: 0 50px;
            
        }

        .shopping-cart-item {
            display: flex;
            margin-bottom: 10px;

            img {
                max-width: 90px;
                height: auto;
            }

            .shopping-cart-item-content {
                padding-left: 10px;
                padding-right: 20px;
                position: relative;
                flex-grow: 1;

                .item {
                    margin-bottom: 5px;
    
                    a {
                        color: $black !important;
                        &:hover {
                            color: $link-color-hover !important;
                        }
                    }
                }

                .item-product-remove {
                    position: absolute;
                    top: 0;
                    right: 0;
                    
                    a i {
                        color: $black !important;
                        font-size: 18px;
                    }

                    a:hover i {
                        color: $link-color-hover !important;
                    }
                
                }
            }
        }

        .product-price {
            font-size: 1rem;
        }

        .regular-price {
            text-decoration: line-through;
        }
    }
}

.cart-curtain {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    
    display: none;
    z-index: 1100;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}



.cartIsOpen {
    overflow: hidden;

    .cart-curtain {
        display: block;
        opacity: 1;
    }

    .shopping-cart-content {
        right: 0;
    }
}
