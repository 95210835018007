@mixin search-box {
  form {
    position: relative;

    input[type="text"] {
      min-width: 255px;
      padding: 10px;
      color: $gray;
      border: none;
      border: $input-btn-border-width solid $input-border-color;

      &:focus {
        color: $gray-darker;
        background: $white;
        outline: 3px $brand-primary solid;

        + button .search {
          color: $brand-primary;
        }
      }
    }

    button[type="submit"] {
      position: absolute;
      right: 0.125rem;
      bottom: 0.3125rem;
      color: $gray;
      background: none;
      border: none;

      .search:hover {
        color: $brand-primary;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-light {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
