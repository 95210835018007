#wrapper, #breadcrumb-wrapper {
  padding-top: 0.5rem;
  background: $white;

  .banner {
    display: block;
    margin-bottom: 1.5rem;
  }

  .breadcrumb {
    padding: 0;
    background: transparent;

    &[data-depth="1"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;
      font-size: $font-size-sm; // tw

      &::after {
        margin: 0.3125rem;
        color: $gray;
        content: "/";
      }

      &:last-child {
        margin: 0;
        color: $gray;
        content: "/";

        &::after {
          content: "";
        }
      }

      a {
        color: $gray-darker;
        // color: $black;
        
      }
    }
  }
}
