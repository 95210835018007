#manufacturer {
  #main {

    .man-nav-title {
      margin-top: 1rem;
      padding-left: 1rem;
    }

    ul.manufacturers-nav {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      li {
        display: block;
        margin: 0 5px 10px;
        

        a {
          padding: 5px 10px;
          color: $black;
          background-color: $gray-light;
          width: 35px;
          text-align: center;
          display: block;

          &:hover {
            background-color: $link-color-hover;
            color: $white;
          }
        }
      }
    }

    .group-title {
      padding-left: 1rem;
      font-size: 2rem;
      line-height: normal;
      border-bottom: 1px solid $title-border-color;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      // align-items: flex-start;



      .brand {
        position: relative;
        flex-basis: 150px;
        padding: 10px;
        margin: 5px;
        text-align: center;
        background: $white;
        transition: 0.4s ease-out;
        border: 1px solid $item-border-color;
        border-radius: $item-border-radius;

        &-infos {
          a {
            display: inline-block;
          }
        }

        &:hover {
          box-shadow: $item-box-shadow-hover;
        }

        &-products {
          display: none;
        }
        
        &-img {
          position: relative;
          margin: auto;
          a {
            .nb-products {
              position: absolute;
              left: 0;
              top: 0;
              padding: 4px 6px;
              background-color: #ccccccc9;
              border-radius: 3px;
            }
    

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              width: 100%;
              height: 100%;
              content: "";
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #manufacturer {
    #main {
      ul {
        display: flex;
        flex-wrap: wrap;
        // align-items: flex-start;
        justify-content: center;
      }
    }
  }
}
