.carousel {
  margin-bottom: 1.5rem;

  .direction {
    z-index: auto;
  }

  .carousel-inner {
    height: 340px;
  }

  .carousel-item {
    height: 100%;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    .caption {
      position: absolute;
      bottom: 28px;
      left: 90px;
      max-width: 340px;
      color: $white;

      .caption-description {
        p {
          font-size: 1rem;
          color: $white;
        }

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
        }
      }

      .display-1 {
        font-size: 3rem;
        font-weight: 700;
      }
    }

    figure {
      display: inline-block;
      margin: 0;
    }
  }

  .carousel-control {
    opacity: 1;

    .icon-next,
    .icon-prev {

      @at-root .lang-rtl & {
        .material-icons {
          transform: rotate(-180deg);
        }
      }

      &::before {
        content: "";
      }

      i {
        font-size: 3.125rem;
        color: $white;
      }

      &:hover {
        i {
          color: $brand-primary;
        }
      }
    }

    .icon-prev {
      left: 1rem;
    }

    .icon-next {
      right: 2rem;
    }

    &.left,
    &.right {
      background: none;
    }
  }
}

.carousel-indicators {
  li {
    border-color: $gray;
  }

  .active {
    background-color: $gray;
  }
}

@include media-breakpoint-up(lg) {
  .carousel {
    .carousel-indicators {
      display: none;
    }
  }
}

@include media-breakpoint-down(lg) {
  .carousel {
    .carousel-inner {
      height: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel {
    left: 50%;
    width: 100vw;
    padding-bottom: 0.5rem;
    margin-left: -50vw;
    background-color: $gray-light;
    box-shadow: none;

    .carousel-control {
      .icon-prev,
      .icon-next {
        width: auto;
        height: auto;
      }
    }

    .carousel-item {
      .caption {
        position: static;
        margin: 1.275rem auto 2.5rem;
        text-align: center;

        .display-1 {
          font-size: 2rem;
          font-weight: 500;
          color: $gray-darker;
        }

        .caption-description {
          h3,
          p {
            color: $gray;
          }

          h3 {
            font-size: 1rem;
          }
        }
      }
    }

    .direction {
      position: absolute;
      top: calc(50%);
      width: 100%;

      .carousel-control {
        .icon-prev {
          left: 0;
          margin-left: 0;
        }

        .icon-next {
          right: 0;
          margin-right: 0;
        }

        i {
          background: rgba(122, 122, 122, 0.5);
        }
      }
    }
  }

  .carousel-indicators {
    li {
      width: 8px;
      height: 8px;
    }

    .active {
      width: 8px;
      height: 8px;
      margin: 1px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .carousel {
    .carousel-item {
      .caption {
        .display-1 {
          font-size: 1.3rem;
        }
      }
    }

    .carousel-control {
      .icon-prev,
      .icon-next {
        i {
          font-size: 2rem;
        }
      }
    }
  }
}
