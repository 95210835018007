.btn-primary,
.btn-secondary {
  padding: 0.5rem 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  // border: 2px solid #fff;

  .material-icons {
    margin-right: $small-space;
    // font-size: $base-font-size;
  }
}

.btn-border-color {
  border-color: #ffffff
}

.btn-tertiary {
  @extend .btn-secondary;
  padding: 0.25rem;
  margin: 0.25rem 0;
  font-size: $font-size-sm;
  font-weight: 400;
  color: $gray;
  text-transform: lowercase;
  background-color: #f7f7f7;

  .material-icons {
    font-size: $base-font-size;
  }

  &:hover {
    border: $input-btn-border-width solid rgba(35, 35, 35, 0.2);
  }
}

.btn-unstyle {
  padding: 0;
  text-align: inherit;
  background-color: transparent;
  border: none;

  &:focus {
    outline: 0;

    .expand-more {
      color: $brand-primary;
    }
  }
}

.btn-download {
  @extend .btn-primary;
  padding: 0.5rem;
  margin: 0 0 0.25rem 0;
  font-size: $font-size-sm;
  text-transform: none;
  // color: $black;
  // background-color: #f1f1f1;
  // border: $input-btn-border-width solid $item-border-color;

  .material-icons {
    font-size: $base-font-size;
    margin-right: 0.25rem;
  }

  // &:hover {
  //   border: $input-btn-border-width solid $link-color-hover;
  //   color: $link-color-hover;
  //   background-color: #f1f1f1;
  // }
}


.btn-primary {
    background-color: $highlighted-color;
    border-radius: $btn-border-radius;
    &:hover {
        background-color: $black;    
    }
}

.btn-alternate {
    border: 2px solid $highlighted-color;
    background-color: $white;
    border-radius: $btn-border-radius;
    color: $highlighted-color;
    &:hover {
        background-color: $highlighted-color; 
        color: $white !important;
    }
}