#header {
  position: relative;
  z-index: 999;
  color: $header-text-color;
  // background: $black;
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);

  .logo {
    max-width: 100%;
    height: auto;
  }

  #_desktop_logo {
    h1 {
      margin-bottom: 0;
    }
  }

  a {
    color: $header-text-color;

    &:hover {
      color: $highlighted-color;
      text-decoration: none;
    }
  }

  .menu {
    display: inline-block;

    > ul {
      > li {
        display: inline-block;
      }
    }
  }

  .dropdown{
    .expand-more {
      color: $header-text-color;
      &:hover {
        color: $highlighted-color;
      }

    }

  } 

  .header-nav {
    
    
    // border-bottom: $highlighted-color 2px solid;
    // background: $gray-lighter-second;
    color: $header-text-color;
    padding: 0.375rem 0;
    z-index: 10;

    .nav-container {
      max-height: 50px;
      padding: 1px 0;
    }

    .dropdown-menu {
      padding-left: 5px;
      padding-right: 5px;
      margin-top: -2px;

      li {
        &:not(:last-child) {
          margin-bottom: 5px;
        }

        a {
          padding: 5px 10px;
          color: $gray-darker;
          
          background-color: $gray-light-darker;
          &:hover {
            // background-color: $gray-light-darker;
            color: $highlighted-color;
          }
        }

      }
    }

    // a {
    //   color: $header-text-color;
    // }

    i {
      color: $highlighted-color;
    }

    #menu-icon {
      margin: 0 1rem;
      vertical-align: middle;
      cursor: pointer;

      .material-icons {
        line-height: 50px;
      }
    }

    .right-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    .currency-selector {
      margin: 0 0.75rem;
      white-space: nowrap;
      button {
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;
      }

    }

    .user-info {
      margin-top: 0.9375rem;
      margin-left: 2.5rem;
      text-align: right;
      white-space: nowrap;

      .account {
        margin-left: $small-space;
      }
    }

    .language-selector {
      // margin-top: 0.9375rem;
      margin: 0 0.75rem;
      // padding: 0 0 0 0.75rem;
      white-space: nowrap;
    }

    .cart-preview {
      &.active {
        background: transparent;

        a {
          
        }
        i {
          color: $highlighted-color;
        }
      }

      .shopping-cart {
        color: $gray;
        vertical-align: middle;
      }

      .body {
        display: none;
      }
    }

    .header-btn {
      // height: 3rem;
      padding: 6px 0.3rem;
      // margin-left: 0.9375rem;
      margin-left: 0.5rem;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      
      
      // a {
      //   color: $header-text-color;

      //   &:hover {
      //     color: $header-text-color;
      //   }
      // }

      

      // &.active {
        
      //   a:hover {
      //     color: $gray-dark;
      //   }
      // }

      .header {
        margin-top: 0.125rem;
      }
    }

    #_desktop_contact_link {
      display: inline-block;

      #contact-link {
        // margin-top: 0.9375rem;
      }
    }

    .search-widget {
      margin-top: 0.2rem;
    }

    .material-icons {
      // line-height: inherit;

      &.expand-more {
        margin-left: -0.375rem;
      }
    }
  }

  .header-top {
    padding: 1.25rem 0;

    > .container {
      position: relative;

      > .row:first-of-type {
        display: flex;
        align-items: center;
      }
    }

    .menu {
      padding-left: 15px;
    }

    .position-static {
      position: static;
    }

    a[data-depth="0"] {
      color: $gray;
      text-transform: uppercase;
    }

    .search-widget {
      float: right;
    }
  }

  .top-menu-link {
    margin-left: $medium-space;
  }

  @include media-breakpoint-down(sm) {
    .top-logo {
      display: flex;
      align-items: center;
      min-height: 50px;

      img {
        width: auto;
        max-height: 2rem;
      }

      > h1 {
        margin: 0;
      }
    }
  }
}
