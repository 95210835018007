#subcategories {
  margin-bottom: 20px;
  // border-top: 1px solid $gray-light;

  .subcategory-heading {
    margin-bottom: 20px;
    font-weight: 500;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    // margin: 0 0 0 -10px;
    gap: 0.5rem;

    li {
      text-align: center;
      background-color: $gray-lighter-second;
      padding: 10px;
      border-radius: $btn-border-radius;

      // @include media-breakpoint-down(xs) {
      //   width: 100%;
      //   text-align: left;
      //   &:nth-child(2n) {
      //     background-color: #595959;
      //   }
      // }

      a {
        color: $gray-darker;
      }

      h5 {
        margin: 0;
      }

      .subcategory-image {
        padding: 0 0 8px;

        a {
          display: block;
          padding: 9px;
          border: 1px solid $gray-light;

          img {
            max-width: 100%;
            vertical-align: top;
          }
        }
      }

      .subcategory-name {
        // text-transform: uppercase;

        &:hover {
          // color: $brand-primary;
          opacity: 0.8;
        }
      }

      .cat_desc {
        display: none;
      }

      &:hover {
        .subcategory-image {
          a {
            padding: 5px;
            border: 5px solid $brand-primary;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #subcategories {
    ul {
      justify-content: center;
    }
  }
}
